import { SPEAKERS_SERVICE } from "@/services/speakers.service.js";

const state = { listOutput: [], speakerOutput: {}, processTask: false, removeTask: false};

const actions = {
    getList({ dispatch, commit }) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        SPEAKERS_SERVICE.list()
        .then(
            output => {
                commit('listSuccess', output);
            },
            error => {
                commit('listFailure', error);
                dispatch('alert/error', error, { root: true });
            }
        );
    },
    editSpeakers({ dispatch, commit }, speakersInfo) {
        dispatch('alert/clear', null, { root: true });
        commit('editRequest');
        console.log(speakersInfo);
        SPEAKERS_SERVICE.edit(speakersInfo.speak,speakersInfo.photo)
            .then(
                output => {
                    commit('editSuccess', output);
                },
                error => {
                    commit('editFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    addSpeakers({ dispatch, commit }, speakersInfo) {
        dispatch('alert/clear', null, { root: true });
        commit('addRequest');
        
        SPEAKERS_SERVICE.add(speakersInfo.speak, speakersInfo.photo)
        .then(
            output => {
                    commit('addSuccess', output);
                },
                error => {
                    commit('addFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getSpeakers({ dispatch, commit }, speakerId) {
        dispatch('alert/clear', null, { root: true });
        commit('getRequest');

        SPEAKERS_SERVICE.get(speakerId)
            .then(
                output => {
                    commit('getSuccess', output);
                },
                error => {
                    commit('getFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    removeSpeaker({ dispatch, commit }, speakerId) {
        dispatch('alert/clear', null, { root: true });
        commit('removeRequest');

        SPEAKERS_SERVICE.remove(speakerId)
            .then(
                output => {
                    commit('removeSuccess');
                },
                error => {
                    commit('removeFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* List */
    listRequest(state) {
        //
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = [];
    },
    /* Add */
    addRequest(state) {
        state.processTask = null;
    },
    addSuccess(state) {
        state.processTask = true;
    },
    addFailure(state) {
        state.processTask = false;

    },
    /* Edit */
    editRequest(state) {
        state.processTask = null;
    },
    editSuccess(state) {
        state.processTask = true;
    },
    editFailure(state) {
        state.processTask = false;

     },
    /* Get */
    getRequest(state) {
        //
    },
    getSuccess(state, output) {
        state.speakerOutput = output;
    },
    getFailure(state) {
        state.speakerOutput = {};
    },
   /* Remove */
   removeRequest(state) {
    state.removeTask = undefined;
    },
    removeSuccess(state) {
        state.removeTask = true;
    },
    removeFailure(state) {
        state.removeTask = false;
    },
    }; 

export const speakers = {
    namespaced: true,
    state,
    actions,
    mutations
};