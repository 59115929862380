import { SPONSORS_SERVICE } from "@/services/sponsors.service.js";


const state = { listOutput: [], processTask: false, sponsorsOutput: {}, removeTask: false};

const actions = {
    getList({ dispatch, commit }) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        SPONSORS_SERVICE.list( )
        .then(
            output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    editSponsors({ dispatch, commit }, sponsorsInfo) {
        dispatch('alert/clear', null, { root: true });
        commit('editRequest');
        
        SPONSORS_SERVICE.edit(sponsorsInfo.spons,sponsorsInfo.photo)
            .then(
                output => {
                    commit('editSuccess', output);
                },
                error => {
                    commit('editFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    addSponsors({ dispatch, commit }, sponsorsInfo) {
        dispatch('alert/clear', null, { root: true });
        commit('addRequest');

        SPONSORS_SERVICE.add(sponsorsInfo.spons,sponsorsInfo.photo)
            .then(
                output => {
                    commit('addSuccess', output);
                },
                error => {
                    commit('addFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getSponsors({ dispatch, commit }, sponsorsId) {
        dispatch('alert/clear', null, { root: true });
        commit('getRequest');

        SPONSORS_SERVICE.get(sponsorsId)
            .then(
                output => {
                    commit('getSuccess', output);
                },
                error => {
                    commit('getFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    removeSponsors({ dispatch, commit }, sponsorsId) {
        dispatch('alert/clear', null, { root: true });
        commit('removeRequest');

        SPONSORS_SERVICE.remove(sponsorsId)
            .then(
                output => {
                    commit('removeSuccess');
                },
                error => {
                    commit('removeFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
/* List */
listRequest(state) {
    //
},
listSuccess(state, output) {
    state.listOutput = output;
},
listFailure(state) {
    state.listOutput = [];
},
/* Add */
addRequest(state) {
    state.processTask = null;
},
addSuccess(state) {
    state.processTask = true;
},
addFailure(state) {
    state.processTask = false;

},
/* Edit */
editRequest(state) {
    state.processTask = null;
},
editSuccess(state) {
    state.processTask = true;
},
editFailure(state) {
    state.processTask = false;

 },
/* Get */
getRequest(state) {
    //
},
getSuccess(state, output) {
    state.sponsorsOutput = output;
},
getFailure(state) {
    state.sponsorsOutput = {};
},
/* Remove */
removeRequest(state) {
state.removeTask = undefined;
},
removeSuccess(state) {
    state.removeTask = true;
},
removeFailure(state) {
    state.removeTask = false;
},
}; 

export const sponsors = {
    namespaced: true,
    state,
    actions,
    mutations
};