import { SCHEDULE_SERVICE } from "@/services/schedule.service.js";

const state = { listOutput: [], scheduleOutput: {}, processTask: false, removeTask: false};

const actions = {
    getList({ dispatch, commit }) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        SCHEDULE_SERVICE.list()
        .then(
            output => {
                commit('listSuccess', output);
            },
            error => {
                commit('listFailure', error);
                dispatch('alert/error', error, { root: true });
            }
        );
    },
    editSchedule({ dispatch, commit }, scheduleInfo) {
        dispatch('alert/clear', null, { root: true });
        commit('editRequest');                              
        console.log(scheduleInfo);
        SCHEDULE_SERVICE.edit(scheduleInfo.sche,scheduleInfo.photo)
            .then(
                output => {
                    commit('editSuccess', output);
                },
                error => {
                    commit('editFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    addSchedule({ dispatch, commit }, scheduleInfo) {
        dispatch('alert/clear', null, { root: true });          
        commit('addRequest');

        SCHEDULE_SERVICE.add(scheduleInfo.sche,scheduleInfo.photo)
            .then(
                output => {
                    commit('addSuccess', output);
                },
                error => {
                    commit('addFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getSchedule({ dispatch, commit }, scheduleId) {
        dispatch('alert/clear', null, { root: true });
        commit('getRequest');

        SCHEDULE_SERVICE.get(scheduleId)
            .then(
                output => {
                    commit('getSuccess', output);
                },
                error => {
                    commit('getFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    removeSchedule({ dispatch, commit }, scheduleId) {
        dispatch('alert/clear', null, { root: true });
        commit('removeRequest');

        SCHEDULE_SERVICE.remove(scheduleId)
            .then(
                output => {
                    commit('removeSuccess');
                },
                error => {
                    commit('removeFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* List */
    listRequest(state) {
        //
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = [];
    },
    /* Add */
    addRequest(state) {
        state.processTask = null;
    },
    addSuccess(state) {
        state.processTask = true;
    },
    addFailure(state) {
        state.processTask = false;

    },
    /* Edit */
    editRequest(state) {
        state.processTask = null;
    },
    editSuccess(state) {
        state.processTask = true;
    },
    editFailure(state) {
        state.processTask = false;

     },
    /* Get */
    getRequest(state) {
        //
    },
    getSuccess(state, output) {
        state.scheduleOutput = output;
    },
    getFailure(state) {
        state.scheduleOutput = {};
    },
   /* Remove */
   removeRequest(state) {
    state.removeTask = undefined;
    },
    removeSuccess(state) {
        state.removeTask = true;
    },
    removeFailure(state) {
        state.removeTask = false;
    },
    }; 

export const schedule = {
    namespaced: true,
    state,
    actions,
    mutations
};