/* Imports */
import { API_HELPER } from "@/helpers/api.js";
//import { ENVIRONMENT_HELPER } from "@/helpers/environment.js";
import { authHeader } from "@/helpers/auth-header.js";

/* Exports */
// API services that can be invoked on the modules
export const MEETUPS_SERVICE = {
    list,
    add,
    edit,
    get,
    remove
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "Meetups";

/* Services */
function list()
{   
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);    
   
    const requestOptions = {
        method: 'GET' ,
        headers: { ...authHeader()}
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url,null, requestOptions);
}

function add(name,desc,date) {

    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);    
    //console.log(url)
    var raw = JSON.stringify({"name":name, "desc":desc , 'date': date});
    console.log(raw)
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), "Content-Type":"application/json"},
        body: raw
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}

function edit(id,name,desc,date) {
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE,id]); 
    // Send only necessary info
    var raw = JSON.stringify({"id":id,"name":name, "desc":desc ,'date': date});
    console.log(raw)
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), "Content-Type":"application/json" },
        body: raw
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}

function get(id)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, id]);    
    const requestOptions = {
        method: 'GET' ,
        headers: { ...authHeader()}
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}

function remove(id)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, id]);    
    const requestOptions = {
        method: 'DELETE' ,
        headers: { ...authHeader()}
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}