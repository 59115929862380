import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from '@/store/store.js'
import { API_HELPER } from "@/helpers/api.js";
import Vuelidate from 'vuelidate'
import moment from 'moment'
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';



Vue.use(datePicker);
Vue.prototype.moment = moment
Vue.use(Vuelidate)
Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

  router.beforeEach((to, from, next) => {
    // cancel pending requests and reset controller
    API_HELPER.abortController.abort();
    API_HELPER.abortController = new AbortController();
    
    // if logged out, process request
    const logoutPages = ['/logout'];
    if (logoutPages.includes(to.path)) {

    store.dispatch('account/logout', null, { root: true });
    return;
  } 
  
  const loggedIn = localStorage.getItem('user');
  //console.log(loggedIn)
  
  const publicPages = ['/login', '/reset-password'];
  
   // redirect to login page if not logged in and trying to access a restricted page
   const authRequired = !publicPages.includes(to.path);

   if (authRequired && !loggedIn) {
    return next('/login');
  }  
  
  // redirect to dashboard to dashboard if logged in and trying to acess logged out pages
  const authNotRequired = publicPages.includes(to.path);
  if (authNotRequired && loggedIn) {
    return next('/');
  } 
  
  next();
});
 
var app = new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
