import Vue from 'vue'
import Router from 'vue-router'


// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Dashboard with users 
const Dashboard = () => import('@/views/users/Dashboard')
const EditUsers = () => import('@/views/users/EditUsers')


// Views - Pages

const Login = () => import('@/views/pages/login/Login')
const ResetPassword = () => import('@/views/pages/login/ResetPassword')


const Sponsors = () => import('@/views/sponsors/sponsors')
const AddSponsors = () => import('@/views/sponsors/AddSponsors')
const EditSponsors = () => import('@/views/sponsors/EditSponsors')

const Speakers = () => import('@/views/speakers/Speakers')
const AddSpeakers = () => import('@/views/speakers/AddSpeakers')
const EditSpeakers = () => import('@/views/speakers/EditSpeakers')

const Schedule = () => import('@/views/schedule/Schedule')
const AddSchedule = () => import('@/views/schedule/AddSchedule')
const EditSchedule = () => import('@/views/schedule/EditSchedule')

const Meetups = () => import('@/views/meetups/Meetup')
const AddMeetups = () => import('@/views/meetups/AddMeetup')
const EditMeetups = () => import('@/views/meetups/EditMeetup')


Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode HASH
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'edit',
          name: 'EditUsers',
          component: EditUsers
        },
        {
          path: 'speakers',
          redirect: '/speakers/list',
          name: 'Speakers',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
             {
               path: 'list',
               name: 'ListSpeakers',
               component: Speakers
             },
            {
              path: 'add',
              name: 'AddSpeakers',
              component: AddSpeakers
            },
            {
              path: 'edit',
              name: 'EditSpeakers',
              component: EditSpeakers
            },
  
          ]
        },
        {
          path: 'sponsors',
          redirect: '/sponsors/list',
          name: 'Sponsors',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'list',
              name: 'ListSponsors',
              component: Sponsors
            },
            
           {
             path: 'add',
             name: 'AddSponsors',
             component: AddSponsors
           },
           {
             path: 'edit',
             name: 'EditSponsors',
             component: EditSponsors
           },
           
          ]
        },

        {
          path: 'schedule',
          redirect: '/schedule/list',
          name: 'Schedule',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
             {
               path: 'list',
               name: 'ListSchedule',
               component: Schedule
             },
            {
              path: 'add',
              name: 'AddSchedule',
              component: AddSchedule
            },
            {
              path: 'edit',
              name: 'EditSchedule',
              component: EditSchedule
            },
  
          ]
        },

        {
          path: 'meetups',
          redirect: '/meetups/list',
          name: 'Meetups',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'list',
              name: 'ListMeetups',
              component: Meetups
            },
            
           {
             path: 'add',
             name: 'AddMeetups',
             component: AddMeetups
           },
           {
             path: 'edit',
             name: 'EditMeetups',
             component: EditMeetups
           },
           
          ]
        },
      ]
    },

    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: ResetPassword
    },
  ]
}

