import { USER_SERVICE } from "@/services/user.service.js";
import router from '@/router/index';

const user = JSON.parse(localStorage.getItem('user'));
const state = user
    ? { status: { loggedIn: true }, update: undefined, requestLink: undefined}
    : { status: {}, user: null, update: undefined, requestLink: undefined}; 

const actions = {
    login({ dispatch, commit }, { email, password }) {
        dispatch('alert/clear', null, { root: true });
        commit('loginRequest', { email });
        
        USER_SERVICE.login(email, password)
        
        .then( 
            user => {
                commit('loginSuccess', user);
                router.push('/');
            },
            error => {
                commit('loginFailure', user);                
                dispatch('alert/error', error, { root: true });
                
            }
            
            );
    },
    requestLinkPassword({ dispatch, commit }, email) {
        dispatch('alert/clear', null, { root: true });
        commit('linkRequest');

        USER_SERVICE.requestLink(email)
            .then(
                output => {
                    commit('linkSuccess');
                    alert("Check your email!")
                    location.reload()
                },
                error => {
                    commit('linkFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },

    updatePassword({ dispatch, commit }, input)
    {
        dispatch('alert/clear', null, { root: true });
        commit('updateRequest');
     
            USER_SERVICE.updatePassword(input)
            .then(
                output => {
                    commit('updateSuccess');
                },
                error => {
                    commit('updateFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },

    logout({ dispatch, commit }) {
        dispatch('alert/clear', null, { root: true });
        commit('logout');
        USER_SERVICE.logout();
    }
};

const mutations = {
    /* Login */
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggedIn: true };
        state.user = user;

        // login successful if there's a jwt token in the response
        if (user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
        }
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
     /* Update password */
     updateRequest(state) {
        state.update = undefined;
    },
    updateSuccess(state) {
        state.update = true;
    },
    updateFailure(state) {
        state.update = false;
    },
     /* Request link */
     linkRequest(state) {
        state.requestLink = undefined;
    },
    linkSuccess(state) {
        state.requestLink = true;
    },
    linkFailure(state) {
        state.requestLink = false;
    },

    logout(state) {
        state.status = {};
        state.user = null;

        localStorage.removeItem('user');
        localStorage.removeItem('locale');

        router.go('/login'); // Force all modules to reset state
    }
};

export const account = {
    namespaced: true,
    state,
    actions,
    mutations
};