/* Imports */
import { API_HELPER } from "@/helpers/api.js";
//import { ENVIRONMENT_HELPER } from "@/helpers/environment.js";
import { authHeader } from "@/helpers/auth-header.js";

/* Exports */
// API services that can be invoked on the modules
export const SCHEDULE_SERVICE = {
    list,
    add,
    edit,
    get,
    remove
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "Schedules";

/* Services */
function list()
{   
    // Build API call.
    
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);    
   
    const requestOptions = {
        method: 'GET' ,
        headers: { ...authHeader()}
    };
    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url,null, requestOptions);
    
}

function add(sche,photo) {

    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);    

    let formData = new FormData();
     
    formData.append("Photo", photo, photo.name);
    console.log(photo)

    formData.append("Name", sche.name);
    formData.append("Title", sche.title);
    formData.append("Info", sche.info);
    formData.append("Desc", sche.desc);
    formData.append("Time", sche.time);
  

    const requestOptions = {
        method: 'POST',
        body: formData,
        headers: { ...authHeader() }
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}

function edit(sche,photo) {
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]); 

    let formData = new FormData();
     
    if(photo instanceof Blob)
    {
        formData.append("Photo", photo, photo.name);
    }
    else
    {
        formData.append("Photo", photo);
    }

    formData.append("Id", sche.id);
    formData.append("Name", sche.name);
    formData.append("Title", sche.title);
    formData.append("Info", sche.info);
    formData.append("Desc", sche.desc);
    formData.append("Time", sche.time);

    const requestOptions = {
        method: 'PUT',
        body: formData,
        headers: { ...authHeader() }
    };


    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}

function get(id)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, id]);    
    const requestOptions = {
        method: 'GET' ,
        headers: { ...authHeader()}
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}

function remove(id)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, id]);    
    const requestOptions = {
        method: 'DELETE' ,
        headers: { ...authHeader()}
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}