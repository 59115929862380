import { USER_SERVICE } from "@/services/user.service.js";

const state = { listOutput: [], processTask: false};

const actions = {
    getList({ dispatch, commit }) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        USER_SERVICE.list()
        .then(
            output => {
                commit('listSuccess', output);
            },
            error => {
                commit('listFailure', error);
                dispatch('alert/error', error, { root: true });
            }
        );
    },
    editUsers({ dispatch, commit }, UsersInfo) {
        dispatch('alert/clear', null, { root: true });
        commit('editRequest');
        
        USER_SERVICE.edit(UsersInfo.email,UsersInfo.phoneNumber,UsersInfo.firstName,UsersInfo.lastName)
            .then(
                output => {
                    commit('editSuccess', output);
                },
                error => {
                    commit('editFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* List */
    listRequest(state) {
        //
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = [];
    },
   
    /* Edit */
    editRequest(state) {
        state.processTask = null;
    },
    editSuccess(state) {
        state.processTask = true;
    },
    editFailure(state) {
        state.processTask = false;

     },

    }; 

export const users = {
    namespaced: true,
    state,
    actions,
    mutations
};