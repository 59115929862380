/* Imports */
 import { API_HELPER } from "@/helpers/api.js";
 import { ENVIRONMENT_HELPER } from "@/helpers/environment.js";
//import { UTILS_HELPER } from "@/helpers/utils.js";
import { authHeader } from "@/helpers/auth-header.js";  

/* Exports */
// API services that can be invoked on the modules
export const USER_SERVICE = {
    login,
    list,
    edit,
    requestLink,   
    updatePassword,
    logout 
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "Users";

/* Services */
function list() {

    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);

    const requestOptions = {
        method: 'GET' ,
        headers: { ...authHeader()}
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url,null, requestOptions);

}

function edit(email,phoneNumber,firstName,lastName) {
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "UpdateInfo", email]); 
    // Send only necessary info
    var raw = JSON.stringify({"email":email,"phoneNumber":phoneNumber,"firstName":firstName,"lastName":lastName});
    console.log(raw)
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), "Content-Type":"application/json" },
        body: raw
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}

function login(email, password) 
{
    // Mandatory parameters
    if(!email || !password)
    {
        return Promise.reject("The parameters are not complete!");
    }
    
    //
    
    var raw = JSON.stringify({"email": email, "password": password});
    
    const requestOptions = {
        method: 'POST',
        headers: {"Content-Type":"application/json"},
        body: raw
    };
    
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "login", "admin"]);
    
    return processLogin(url, requestOptions);
}

function processLogin(url, requestOptions)
{
    return fetch(encodeURI(url), requestOptions)
    .then(response =>{
        if(ENVIRONMENT_HELPER.isDev()) console.log(response);

        return response.text().then(text => {
            const data = text && JSON.parse(text);

            if (!response.ok) {
                return Promise.reject(data.message);
            }
    
            return data;
        });        
    })
    .then(data => {
        var user = data;

          // login successful if there's a jwt token in the response
        if (user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
        }
  
        return user;
    })
    .catch(error=> {
        var errorMsg;

        if(typeof error != 'string')
        {
            if(ENVIRONMENT_HELPER.isProd())
            {
                errorMsg = "Something went wrong, please try again. If the problem persists contact the support.";
            }
            else
            {
                errorMsg = error.toString();
            }
        }
        else
        {
            errorMsg = error;
        }

        return Promise.reject(errorMsg);
    });
}

function requestLink(email) 
{
    // Mandatory parameters
    if(!email)
    {
        return Promise.reject("The parameters are not complete!");
    }

    //

    const requestOptions = {
        method: 'GET',
    };

    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "Reset", email]);
    
    return API_HELPER.apiCall(url, null, requestOptions);
}

function updatePassword(input)
{
    // Mandatory parameters
    if(!input || !input.password || !input.email || !input.token)
    {
        return Promise.reject("The parameters are not complete!");
    }

    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "Reset"]); 
    var raw = JSON.stringify(input);

    const requestOptions = {
        method: 'PUT',
        body: raw,
        headers: { ...authHeader(), "Content-Type":"application/json" }
    };

    return API_HELPER.apiCall(url, null, requestOptions);
}
 
function logout() {
    // TODO Integrate logout service
    return true;
}