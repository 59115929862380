import { MEETUPS_SERVICE } from "@/services/meetups.service.js";

const state = { listOutput: [], meetupOutput: {}, processTask: false, removeTask: false};

const actions = {
    getList({ dispatch, commit }) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        MEETUPS_SERVICE.list()
        .then(
            output => {
                commit('listSuccess', output);
            },
            error => {
                commit('listFailure', error);
                dispatch('alert/error', error, { root: true });
            }
        );
    },
    editMeetups({ dispatch, commit }, meetupsInfo) {
        dispatch('alert/clear', null, { root: true });
        commit('editRequest');
        
        MEETUPS_SERVICE.edit(meetupsInfo.id,meetupsInfo.name,meetupsInfo.desc,meetupsInfo.date)
            .then(
                output => {
                    commit('editSuccess', output);
                },
                error => {
                    commit('editFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    addMeetups({ dispatch, commit }, meetupsInfo) {
        dispatch('alert/clear', null, { root: true });
        commit('addRequest');

        MEETUPS_SERVICE.add(meetupsInfo.name,meetupsInfo.desc,meetupsInfo.date)
            .then(
                output => {
                    commit('addSuccess', output);
                },
                error => {
                    commit('addFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getMeetups({ dispatch, commit }, meetupId) {
        dispatch('alert/clear', null, { root: true });
        commit('getRequest');

        MEETUPS_SERVICE.get(meetupId)
            .then(
                output => {
                    commit('getSuccess', output);
                },
                error => {
                    commit('getFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    removeMeetup({ dispatch, commit }, meetupId) {
        dispatch('alert/clear', null, { root: true });
        commit('removeRequest');

        MEETUPS_SERVICE.remove(meetupId)
            .then(
                output => {
                    commit('removeSuccess');
                },
                error => {
                    commit('removeFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* List */
    listRequest(state) {
        state.listOutput = null;
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = [];
    },
    /* Add */
    addRequest(state) {
        state.processTask = null;
    },
    addSuccess(state) {
        state.processTask = true;
    },
    addFailure(state) {
        state.processTask = false;

    },
    /* Edit */
    editRequest(state) {
        state.processTask = null;
    },
    editSuccess(state) {
        state.processTask = true;
    },
    editFailure(state) {
        state.processTask = false;

     },
    /* Get */
    getRequest(state) {
        //
    },
    getSuccess(state, output) {
        state.meetupOutput = output;
    },
    getFailure(state) {
        state.meetupOutput = {};
    },
   /* Remove */
   removeRequest(state) {
    state.removeTask = undefined;
    },
    removeSuccess(state) {
        state.removeTask = true;
    },
    removeFailure(state) {
        state.removeTask = false;
    },
    }; 

export const meetups = {
    namespaced: true,
    state,
    actions,
    mutations
};