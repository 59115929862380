import Vue from 'vue'
import Vuex from 'vuex'
import { account } from '@/store/account.module'
import { users } from '@/store/users.module'
import { alert } from '@/store/alert.module'
import { speakers } from '@/store/speakers.module'
import { sponsors } from '@/store/sponsors.module'
import { meetups } from '@/store/meetups.module'
import { schedule } from '@/store/schedule.module'
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  }
}

export default new Vuex.Store({
  state,
  mutations,
  modules: {
    account,
    users,
    alert,
    speakers,
    sponsors,
    meetups,
    schedule
  }
})